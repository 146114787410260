<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>Create Banner Zone</strong>
            </div>
            <div class="text-muted">Create new Banner Zone</div>
          </div>
          <div class="card-body">
            <a-form :form="form" @submit.prevent="handleSubmit">
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Publisher">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Publisher of the Banner Zone.</span>
                      </template>
                      <a-select
                        v-decorator="['publisher', { initialValue: initialValue,rules: [{ required: true, message: 'Please select publisher' }]}]"
                        placeholder="Please select a publisher"
                        :showSearch="true"
                        :filterOption="true"
                        optionFilterProp="children"
                      >
                        <a-select-option v-for="pub in ORTBpublisher.list.filter(ele => ele.Status === 0 )"
                                         :value="pub.Id" :key="pub.Id">[{{pub.Id}}] {{ pub.Name }}
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Name">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Banner Zone's name.</span>
                      </template>
                      <a-input type="text" placeholder="Banner Zone Name" v-decorator="[ 'name', {
                      rules: [
                        { required: true, message: 'Name is required.' },
                        { max: 64, message: 'Maximum 64 characters allowed.'}
                        ]}]"/>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Size">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Banner Zone's placement size in pixels.</span>
                      </template>
                      <a-select
                        v-decorator="['bannerSize', { initialValue: 0, rules: [{ required: false, message: 'Please select a size' }]}]"
                        placeholder="Banner Size"
                        :showSearch="true"
                        :filterOption="true"
                        optionFilterProp="children"
                        @change="isCustomSizeCheck"
                      >
                        <a-select-option :key="index" v-for="(size, index) in bannerSizes">{{
                            size.label
                          }}
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item v-if="isCustomSize" :label-col="labelCol" :wrapper-col="wrapperCol" label="Width">
                    <a-input
                      min="0"
                      max="9999"
                      style="width: 100%"
                      placeholder="Width"
                      v-decorator="['Width',
                        { type: 'number', initialValue: 0, rules: [
                        { required: isCustomSize, message: 'Width is required.' }] }]"/>
                  </a-form-item>

                  <a-form-item v-if="isCustomSize" :label-col="labelCol" :wrapper-col="wrapperCol" label="Height">
                    <a-input
                      min="0"
                      max="9999"
                      style="width: 100%"
                      placeholder="Height"
                      v-decorator="['Height',
                        { type: 'number', initialValue: 0, rules: [
                        { required: isCustomSize, message: 'Height is required.' }] }]"/>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Bidfloor">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Minimum bid expressed in CPM. Value will be used as a bidfloor in RTB auctions.</span>
                      </template>
                      <a-input type="float" min="0" max="100" step="0.0001" placeholder="Bidfloor"
                                v-decorator="[ 'bidfloor', { initialValue: 0.500, rules: [{validator: cpmValidator}] }]">
                        <a-tooltip slot="addonBefore" trigger="click">
                          <a-icon type="dollar"/>
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Publisher Revenue Share">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Percentage of a revenue (bid price minus bid floor) from Remote RTB Zone's advertiser that Banner Zone's publisher earns.</span>
                      </template>
                      <a-input type="number" min="0" max="100" suffix="%"
                                v-decorator="['publisherRevShare', { initialValue: 100 }]"
                                placeholder="Publisher Revenue Share">
                      </a-input>
                      <!-- <a-input  /> -->
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Ad Domains/Brands Blacklist">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of domains or brand names to block.</span>
                      </template>
                      <div class="list-flex">
                        <a-select
                          v-model="adDomainsBrandsBlacklist"
                          placeholder="Ad Domain/Brands Blacklist"
                          :showSearch="true"
                          :filterOption="true"
                          optionFilterProp="children"
                          mode="tags"
                          :tokenSeparators="[' ']"
                        >
                        </a-select>
                        <a-button
                          class="btn-info copy-button-width"
                          @click="copyList('brands')"
                        ><i class="fa fa-copy"></i></a-button>
                      </div>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Ad Apps Blacklist">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Blacklist of applications by their application identifiers. On Android, these should be bundle or package names (e.g., com.foo.mygame), on iOS, these are numeric IDs.</span>
                      </template>
                      <div class="list-flex">
                        <a-select
                          v-model="adAppsBlacklist"
                          placeholder="Ad Apps Blacklist"
                          :filterOption="true"
                          optionFilterProp="children"
                          mode="tags"
                          :tokenSeparators="[' ']"
                        >
                        </a-select>
                        <a-button
                          class="btn-info copy-button-width"
                          @click="copyList('apps')"
                        ><i class="fa fa-copy"></i></a-button>
                      </div>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Keywords">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of keywords describing the site/app.</span>
                      </template>
                      <div class="list-flex">
                        <a-select
                          v-model="keywords"
                          placeholder="Keywords"
                          :showSearch="true"
                          :filterOption="true"
                          optionFilterProp="children"
                          mode="tags"
                          :tokenSeparators="[' ']"
                        >
                        </a-select>
                        <a-button
                          class="btn-info copy-button-width"
                          @click="copyList('keywords')"
                        ><i class="fa fa-copy"></i></a-button>
                      </div>
                    </a-tooltip>
                  </a-form-item>
                </div>
                <div class="w-100">

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Site Domain">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Domain of the site.</span>
                      </template>
                      <a-input type="text"
                               v-decorator="['SiteDomain', { rules: [{ max: 64, message: 'Maximum 64 characters allowed.'} ]}]"
                               placeholder="Site Domain"/>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="App Name">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>App name.</span>
                      </template>
                      <a-input type="text"
                               v-decorator="['AppName', { rules: [{ max: 64, message: 'Maximum 64 characters allowed.'} ]}]"
                               placeholder="App Name"/>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="App Bundle">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>A platform-specific application identifier intended to be unique to the app and independent of the exchange. On Android, these should be bundle or package names (e.g., com.foo.mygame), on iOS, these are numeric IDs.</span>
                      </template>
                      <a-input type="text"
                               v-decorator="['AppBundle', { rules: [{ max: 64, message: 'Maximum 64 characters allowed.'} ]}]"
                               placeholder="App Bundle"/>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="App Store URL">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>App Store URL for an installed app.</span>
                      </template>
                      <a-input type="url"
                               v-decorator="['AppStoreUrl',
                              { rules: [{ max: 256, message: 'Maximum 256 characters allowed.'} ]}]"
                               placeholder="App Store URL"/>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Tmax">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Maximum time in milliseconds to wait for the response from Remote RTB Zone's server.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Tmax"
                              v-decorator="[ 'tmax', { initialValue: 350 }]"/>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Auction Type">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Type of RTB auction within the platform.</span>
                      </template>
                      <a-select
                        v-decorator="['auctionType', { initialValue: '2' }]"
                        placeholder="Auction Type"
                        :filterOption="true"
                        optionFilterProp="children"
                      >
                        <a-select-option value='1'>First Price</a-select-option>
                        <a-select-option value='2'>Second Price Plus</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Secure">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Indicates if the Banner Zone requires secure HTTPS URL creative assets and markup.</span>
                      </template>
                      <a-switch checked-children="Enabled" un-checked-children="Disabled"
                                v-model="secureValue"/>
                    </a-tooltip>
                  </a-form-item>
                </div>
              </div>
              <a-divider style="margin-top: 34px;">Ad Position</a-divider>
              <Bannerposition v-on:setPosition="setAdPosition($event)" :positionKey="adPosition"/>
              <a-divider style="margin-top: 34px;">Limits</a-divider>
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Requests Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of requests to receive per day. The system will even out distribution of requests throughout the day, ie. "day shaping". Set to 0 for unlimited requests.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Requests Daily Limit"
                        v-decorator="['requestsDailyLimit', { initialValue: 0, rules: [{ validator: cpmValidator}] }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Bids Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of bids per day. The system will even out distribution of bids throughout the day, ie. "day shaping". Set to 0 for unlimited bids.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Bids Daily Limit"
                        v-decorator="['bidsDailyLimit', { initialValue: 0, rules: [{ validator: cpmValidator}] }]" />
                    </a-tooltip>
                  </a-form-item>
                </div>
                <div class="w-100">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Impressions Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of impressions per day. The system will even out distribution of impressions throughout the day, ie. "day shaping". Set to 0 for unlimited impressions.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Impressions Daily Limit"
                        v-decorator="['impressionsDailyLimit', { initialValue: 0, rules: [{ validator: cpmValidator}] }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Spent Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Maximum daily amount (USD) to spend on Banner Zone. Set to 0 for unlimited amount.</span>
                      </template>
                      <a-input type="number" min="0" step="0.0001" placeholder="Spent Daily Limit"
                        v-decorator="['spentDailyLimit', { initialValue: 0, rules: [{ validator: cpmValidator }] }]">
                        <a-tooltip slot="addonBefore" trigger="click">
                          <a-icon type="dollar" />
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                </div>
              </div>
              <a-divider style="margin-top: 34px;">Categories & Ad Attributes</a-divider>
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="IAB Categories">
                    <a-tree
                      checkable
                      v-model="checkedKeys"
                      :auto-expand-parent="autoExpandParent"
                      :selected-keys="selectedKeys"
                      :tree-data="treeData"
                      @expand="onExpand"
                      @select="onSelect"
                      optionFilterProp="children"
                    />
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Blocked Ad IAB Categories">
                    <a-tree
                      checkable
                      v-model="checkedBlockedKeys"
                      :auto-expand-parent="autoExpandParent"
                      :selected-keys="selectedBlockedKeys"
                      :tree-data="treeData"
                      @expand="onExpand"
                      @select="onBlockSelect"
                      optionFilterProp="children"
                    />

                  </a-form-item>
                </div>
                <div class="w-100">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Blocked Ad Attributes">
                    <a-tree
                      checkable
                      class="break-line"
                      v-model="checkedBlockedAds"
                      :selected-keys="selectedAdKeys"
                      :tree-data="adTreeData"
                      @expand="onExpand"
                      @select="onBlockedAdSelect"
                      optionFilterProp="children"
                    />
                  </a-form-item>
                </div>
              </div>

              <a-divider class="mt-3"></a-divider>
              <div class="ant-row ant-form-item">
                <div class="ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"></div>
                <div class="ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper">
                  <button type="submit" class="btn btn-success px-5">Create Banner Zone</button>
                  <button type="button" @click="$router.push({ name: 'bannerzones' })"
                          class="btn btn-light px-5 ml-2">Cancel
                  </button>
                </div>
              </div>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getIabCategories } from '@/api/info'
import bannerSizes from '@/views/display/data/zoneSize.json'
import adTreeData from '@/views/display/data/blockedAds.json'
import Vue from 'vue'

export default {
  components: {
    Bannerposition: () => import('@/components/custom/banner-position/index.vue'),
  },
  computed: {
    ...mapState(['ORTBpublisher']),
    // initialValue () {
    //   if (this.$route.params.publisherId) {
    //     return this.ORTBpublisher.list.filter(ele => ele.Id === parseInt(this.$route.params.publisherId))[0].Id
    //   } else { return 'Please select publisher' }
    // },
    initialValue() {
      if (this.$route.params.publisherId) {
        const publisher = this.ORTBpublisher.list.find(ele => ele.Id === parseInt(this.$route.params.publisherId))
        return publisher === undefined ? [] : publisher.Id
      } else {
        return []
      }
    },
    activeKwlist() {
      return this.$store.getters['kwlist/getActiveKwLists']
    },
    keywordString() {
      return this.keywords.join()
    },
    adAppsBlacklistString() {
      return this.adAppsBlacklist.join('\n')
    },
    adDomainsBrandsBlacklistString() {
      return this.adDomainsBrandsBlacklist.join('\n')
    },
  },
  data() {
    return {
      secureValue: true,
      keywords: [],
      adDomainsBrandsBlacklist: [],
      unformattedResponse: [],
      adAppsBlacklist: [],
      treeData: [],
      adTreeData: adTreeData,
      // expandedKeys: ['0-0-0', '0-0-1'],
      autoExpandParent: false,
      checkedBlockedAds: [],
      selectedAdKeys: [],
      checkedKeys: [],
      selectedKeys: [],
      checkedBlockedKeys: [],
      selectedBlockedKeys: [],
      bannerSizes: bannerSizes,
      isCustomSize: false,
      form: this.$form.createForm(this),
      adPosition: -1,
      keywordListsType: [
        {
          Id: 0,
          Name: 'Exact',
        },
        {
          Id: 1,
          Name: 'Phrase',
        },
        {
          Id: 2,
          Name: 'Broad',
        },
      ],
      IabCategories: [],
      keywordLists: [],
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },

    }
  },
  watch: {
    checkedKeys(val) {
      // console.log('onCheck', val)
    },
  },
  methods: {
    cpmValidator(rule, value, message) {
      if (value === '') {
        message()
      } else if (isNaN(parseFloat(value))) {
        message('Invalid number.')
      } else if (parseFloat(value) < 0) {
        message('Value can not be negative.')
      }
      message()
    },
    setAdPosition(key) {
      this.adPosition = key
    },
    copyList(type) {
      if (type === 'apps') {
        navigator.clipboard.writeText(this.adAppsBlacklistString)
        Vue.prototype.$notification.success({
          message: 'Ad Apps Blacklist copied to clipboard.',
        })
      } else if (type === 'brands') {
        navigator.clipboard.writeText(this.adDomainsBrandsBlacklistString)
        Vue.prototype.$notification.success({
          message: 'Ad Domains/Brands Blacklist copied to clipboard.',
        })
      } else if (type === 'keywords') {
        navigator.clipboard.writeText(this.keywords.join('\n'))
        Vue.prototype.$notification.success({
          message: 'Keywords copied to clipboard.',
        })
      }
    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          // TODO: Maybe validate AdDomainsBrandsBlacklist
          if (this.isCustomSize === false && this.bannerSizes[values.bannerSize] !== undefined) {
            const temp = this.bannerSizes[values.bannerSize].size
            delete values.bannerSize
            values.width = temp.width
            values.height = temp.height
          } else {
            values.width = this.width
            values.height = this.height
          }
          const parentSelectedIab = this.checkedKeys.filter(checkedKey => this.unformattedResponse.includes(checkedKey))
          const array = this.treeData.map(element => parentSelectedIab.includes(element.key) ? element.children : []).flat().map(el => el.key)
          const newArr = this.checkedKeys.filter(el => !array.includes(el))

          const parentSelectedBlockedIab = this.checkedBlockedKeys.filter(checkedBlockedKey => this.unformattedResponse.includes(checkedBlockedKey))
          const blockedIabArray = this.treeData.map(blockedElement => parentSelectedBlockedIab.includes(blockedElement.key) ? blockedElement.children : []).flat().map(el => el.key)
          const newBlockedArray = this.checkedBlockedKeys.filter(blockedEl => !blockedIabArray.includes(blockedEl))

          values.AdPosition = this.adPosition
          values.Secure = this.secureValue ? 1 : 0
          values.IabCategories = newArr
          values.BlockedAdIabCategories = newBlockedArray
          values.BlockedAdAttributes = this.checkedBlockedAds
          values.Keywords = this.keywordString
          values.AdAppsBlacklist = this.adAppsBlacklistString
          values.AdDomainsBrandsBlacklist = this.adDomainsBrandsBlacklistString
          if (values.AppStoreUrl !== '' && values.AppStoreUrl) {
            values.AppStoreUrl = values.AppStoreUrl.replace(/ /g, '')
          }
          if (values.SiteDomain !== '' && values.SiteDomain) {
            values.SiteDomain = values.SiteDomain.replace(/ /g, '')
          }
          this.$store.dispatch('bannerzone/CREATE_BANNERZONE', {
            payload: values,
            callback: function () {
              this.$router.push({ name: 'bannerzones' })
            }.bind(this),
          })
        }
      })
    },
    onExpand(expandedKeys) {
      // console.log('onExpand', expandedKeys)
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    onCheck(checkedKeys) {
      // console.log('onCheck', checkedKeys)
      this.checkedKeys = checkedKeys
    },
    onSelect(selectedKeys, info) {
      // console.log('onSelect', info)
      this.selectedKeys = selectedKeys
    },
    onBlockedAdCheck(checkedBlockedAds) {
      // console.log('onCheck', checkedBlockedAds)
      this.checkedBlockedAds = checkedBlockedAds
    },
    onBlockedAdSelect(selectedAdKeys, info) {
      // console.log('onSelect', info)
      this.selectedAdKeys = selectedAdKeys
    },
    onBlockCheck(checkedBlockedKeys) {
      // console.log('onCheck', checkedBlockedKeys)
      this.checkedBlockedKeys = checkedBlockedKeys
    },
    onBlockSelect(selectedBlockedKeys, info) {
      // console.log('onSelect', info)
      this.selectedBlockedKeys = selectedBlockedKeys
    },
    toggleCustomInput() {
      this.showCustomInput = true
    },
    isCustomSizeCheck(val) {
      this.isCustomSize = val === (this.bannerSizes.length - 2)
    },
  },
  created() {
    getIabCategories().then(response => {
      response.forEach(element => {
        this.unformattedResponse.push(element.Name)
      })
      const formattedResponse = []
      response.forEach((el, id) => {
        const temp = {
          title: '(' + el.Name + ')' + ' ' + el.Title,
          key: el.Name,
          children: [],
        }
        el.Subcategories.forEach((element) => {
          temp.children.push({
            title: '(' + element.Name + ')' + ' ' + element.Title,
            key: element.Name,
          })
        })
        formattedResponse.push(temp)
        this.treeData = formattedResponse
      })
    })
    this.$store.dispatch('ORTBpublisher/LOAD_PUBLISHERS_SHORT')
  },
}
</script>
<style lang="css" scoped>
.ant-form-item {
  margin-bottom: 5px !important;
}
.list-flex {
  display: flex;
  align-content: flex-start;
}
.select-width {
  width: 90%;
}
.copy-button-width {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.1rem !important;
  height: 2.1rem !important;
}
@media only screen and (min-width: 768px) {
  .flex {
    display: flex;
    flex-direction: col;
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .-m {
    margin-right: -4rem;
  }

  .break-line >>> li .ant-tree-node-content-wrapper {
    margin-bottom: 5px;
    padding-top: 4px;
    display: inline-block !important;
    white-space: pre-wrap !important;
    line-height: 15px !important;
    width: 90% !important;
  }
}
</style>
